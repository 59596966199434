import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

import { Link, graphql } from "gatsby"

import config from "../../config"

const IndexPage = ({ data }) => (
  <Layout>
    <Bio />
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Website Index for {config.SITE_META_TITLE}</h1>
    <div class="site-index">
      {data.allWpCategory.nodes.map(({ id, name, posts }) => {
        return (
          <div key={id}>
            {posts.nodes.length > 0 && (
              <div>
                <hr></hr>
                {name !== "Uncategorized" &&
                <h1>{name}</h1>
                }
              </div>
            )}
            {posts.nodes.map(({ title, uri, seo }) => {
              return (
                <div class="site-index-post">
                  <Link to={uri}>
                    <h4>
                      <span dangerouslySetInnerHTML={{ __html: title }} />
                    </h4>
                  </Link>
                  <span dangerouslySetInnerHTML={{ __html: seo.metaDesc }} />
                  <Link to={uri}>Read More...</Link>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query {
    allWpCategory(
      sort: { fields: [name], order: ASC }
      filter: { name: { ne: "" } }
    ) {
      nodes {
        id
        name
        posts {
          nodes {
            title
            uri
            seo {
              metaDesc
            }
          }
        }
      }
    }
  }
`
export default IndexPage
